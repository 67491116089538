<template>
    <div class="navmb">
        <div class="flex flexa">
            <span class="">补丁/更新程序</span>
        </div>
    </div>
    <div class="banl">
        <div class="txt">
            <div class="tit">程序和更新下载</div>
            <!-- <div class="desc">
                <p>选择适合自己产品的版本和系统进行下载更新</p>
            </div> -->
        </div>
    </div>
    <div class="m-centerl">
        <div class="m-queryl">
            <div class="g-titl1"><i></i>温馨提示</div>
            <div class="jl-info" v-html="engineersoft"></div>
            <ul class="ul-listl1">
                <li v-for="(item, index) in tabList" :key="index" :class="{ act: param.type == item.type }"
                    @click="tabToggle(item.type)">
                    <p class="con">{{ item.name }}</p>
                </li>
            </ul>
            <!-- <div class="g-titl1"><i></i>兼容性认证查询</div> -->
            <div class="m-selectl">
                <div class="info">
                    <div class="item">
                        <div class="ll">产品：</div>
                        <div class="rr">
                            <el-select-v2 v-model="param_form.productId" placeholder="请选择" class="w242" filterable
                                clearable :props="{
                    value: 'id',
                    label: 'productName'
                }" :loading="vselect.product.loading" :options="vselect.product.options"
                                @change="vselect_version($event); queryList()" @clear="queryList" />
                        </div>
                    </div>
                    <div class="item">
                        <div class="ll">产品版本：</div>
                        <div class="rr">
                            <el-select-v2 v-model="param_form.productVersionId" placeholder="请选择" class="w242"
                                filterable clearable :props="{
                                value: 'id',
                                label: 'versionName'
                            }" :loading="vselect.version.loading" :options="versionMapList" @change="queryList"
                                            @clear="queryList" />
                        </div>
                    </div>
                    <!-- <div class="c"></div> -->
                    <div class="item">
                        <div class="ll">硬件平台：</div>
                        <div class="rr">
                            <el-select v-model="param_form.hardwarePlatformId" class="w242" placeholder="选择硬件平台"
                                clearable @change="queryList" @clear="queryList">
                                <el-option v-for="item in hardwarePlatform" :key="item.dictValue"
                                    :label="item.dictLabel" :value="item.dictValue" />
                            </el-select>
                        </div>
                    </div>
                    <div class="item">
                        <div class="ll">操作系统版本：</div>
                        <div class="rr">
                            <el-cascader v-model="param_form.operatingSystemVersionId" class="w242" clearable
                                :props="system.props" :options="system.options" placeholder="请选择系统版本"
                                @change="queryList" @clear="queryList" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="jl-tab1">
                <el-table v-loading="loading" :data="list" style="width: 100%" :header-cell-style="tabHdStyle">
                    <el-table-column :show-overflow-tooltip="true" prop="title" label="软件包名称" min-width="160"></el-table-column>
                    <el-table-column prop="fileSize" label="软件包大小" min-width="80">
                    </el-table-column>
                    <el-table-column class-name="word-break-all" prop="productVersionName" label="产品版本" min-width="100">
                        <template #default="scope">
                            <p :key="scope.$index+','+item" v-for="item in scope.row.productVersionName.split(',')" class="wordw-normal ww100">{{ item }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="hardwarePlatformLabel" label="硬件平台" min-width="100">
                    </el-table-column>
                    <el-table-column class-name="word-break-all" prop="operatingSystemVersionName" label="操作系统版本" min-width="100">
                        <template #default="scope">
                            <p :key="scope.$index+','+item" v-for="item in scope.row.operatingSystemVersionName.split(',')" class="wordw-normal ww100">{{ item }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="download" min-width="70" label="软件包下载">
                        <template #default="scope">
                            <div @click="clickXz(scope.row.path, scope.row.title, scope.row.id)" href="" class="btn point">立即下载<i
                                    :style="'background-image: url(' + dawloadImg + ');'"></i></div>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="70" prop="verify" label="完整性校验">
                        <template #default="scope">
                            <div @click="clickFz(scope.row.sha)" class="txt point">SHA256
                                <!-- <el-icon  > -->
                                <DocumentCopy class="w15 h15" />
                                <!-- </el-icon> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="download" min-width="70" label="文档说明下载">
                        <template #default="scope">
                            <div @click="clickXz(scope.row.readFile, scope.row.title+'文档说明', scope.row.id)" href="" class="btn point">立即下载<i
                                    :style="'background-image: url(' + dawloadImg + ');'"></i></div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flexsb flex ww100">
                <p>共{{total}}条</p>
                <el-pagination @update:page-size="handleSizeChange" @update:current-page="getList"
                    v-model:currentPage="param.pageNumber" :page-sizes="[10, 20, 30, 40]" :page-size="param.pageSize"
                    layout="sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>

import { DocumentCopy } from '@element-plus/icons-vue'
import { mapGetters } from 'vuex'
import { engineersoftGetPrompt } from "@/utils/api1.js"

export default {
    setup() {
        return {
            tabList: [
                { name: '软件包下载', type: 1 },
                { name: '补丁程序下载', type: 2 }
            ],
        }
    },
    components: {
        DocumentCopy
    },
    computed: {
        ...mapGetters({
            hardwarePlatform: 'cache/getHardwarePlatform',
        }),
        versionMapList() {
            return this.vselect.version.map_options.get(this.param_form.productId) ?? []
        },
    },
    data() {
        return {
            engineersoft:'',
            vselect: {
                product: {
                    loading: false,
                    options: [],
                    map_options: new Map()
                },
                version: {
                    loading: false,
                    map_options: new Map()
                },
            },
            system: {
                props: {
                    checkStrictly: false,
                    emitPath: true,
                    value: 'id',
                    label: 'title',
                    children: 'list'
                },
                options: [],
            },
            param_form: {
                productId: undefined,
                productVersionId: undefined,
                operatingSystemId: undefined,
                operatingSystemVersionId: undefined,
                hardwarePlatformId: undefined,
            },
            param: {
                type: '1',
                pageNumber: 1,
                pageSize: 10
            },
            loading: false,
            list: [],
            total: 0,
            dawloadImg: require('../../assets/imgs/icon6-a.png'),
            verifyImg: require('../../assets/imgs/add.png'),
            tabHdStyle: {
                'background-color': '#F0F0F0',
                'color': '#303030',
            },
        }
    },
    mounted() {
        this.$store.dispatch('cache/getHardwarePlatformList')
        this.operatingSystemList()
        this.vselect_product()
        this.getList()
        this.getEngineersoft()
    },
    methods: {
        
        getEngineersoft(){
            engineersoftGetPrompt().then(res=>{
                this.engineersoft = res.content
            })
        },
        async clickXz(url, title, id) {//下载文件            
            try {
                console.log(url)
                const link = document.createElement('a');
                link.href = url.split('?uploadId=')[0];
                link.download = title;
                // link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
                link.click();
                return
                if (url) {
                    const { isDownload } = await this.$api.patch.check({ id })
                    if (isDownload == 0) {
                        return this.$message.warning('您绑定的CSI暂不支持下载该类型补丁/程序')
                    }
                    let xhr = new XMLHttpRequest()
                    xhr.open('GET', url.split('?uploadId=')[0], true)
                    xhr.responseType = 'blob';
                    xhr.onload = function (e) {
                        //如果请求执行成功
                        if (this.status == 200) {
                            var blob = this.response;
                            var filename = title;//如123.xls
                            var a = document.createElement('a');
                            //创键临时url对象
                            var url = URL.createObjectURL(blob);

                            a.href = url;
                            a.download = filename;
                            a.click();
                            //释放之前创建的URL对象
                            window.URL.revokeObjectURL(url);
                        }
                    };
                    //发送请求
                    xhr.send();
                }
            } catch (e) {
                return console.log(e)
            }
        },
        clickFz(value) {
            const input = document.createElement('input');
            input.value = value; // 设置要复制的文本内容
            // 将输入框添加到页面中
            document.body.appendChild(input);
            // 选中输入框的内容
            input.select();
            // 执行复制操作
            document.execCommand('copy');
            // 移除临时创建的输入框
            document.body.removeChild(input);
            this.$message.success('复制成功')
        },
        tabToggle(type) {
            this.param.type = type
            this.queryList()
            return
            this.param_form = {
                productId: undefined,
                productVersionId: undefined,
                operatingSystemId: undefined,
                operatingSystemVersionId: undefined,
                hardwarePlatformId: undefined,
            }
            this.queryList()
        },
        async vselect_version(id) {
            try {
                this.param_form.productVersionId = undefined
                this.param.productVersionId = undefined
                this.vselect.version.map_options.set(
                    id,
                    this.vselect.version.map_options.get(id) ?? (
                        await this.$api.bug.versionList({
                            productId: id,
                            pageNumber: 1,
                            pageSize: 2000
                        })
                    ).records
                )
                this.vselect.version.loading = false
            } catch (e) {
                this.vselect.version.loading = false
                this.vselect.version.options = []
            }
        },
        async vselect_product() {
            try {
                this.vselect.product.loading = true
                const { records, total } = await this.$api.bug.productList({
                    type: 1,
                    isSoft: 1,
                    pageNumber: 1,
                    pageSize: 1500
                })
                this.vselect.product.map_options = new Map(
                    records.map(item => [item.id, item])
                )
                this.vselect.product.options = records
                this.vselect.product.loading = false
            } catch (e) {
                this.vselect.product.loading = false
                this.vselect.product.options = []
            }
        },
        async operatingSystemList() {
            try {
                const { records } = await this.$api.base.operatingSystemList({
                    pageNumber: 1,
                    pageSize: 2000
                })
                this.system.options = records
            } catch (e) {
                return console.log(e)
            }
        },
        queryList() {
            this.param.productId = this.param_form.productId
            this.param.hardwarePlatformId = this.param_form.hardwarePlatformId
            this.param.operatingSystemId = this.param_form.operatingSystemVersionId?.[0]
            this.param.operatingSystemVersionId = this.param_form.operatingSystemVersionId?.[1]
            this.param.productVersionId = this.param_form.productVersionId
            this.getList(1)
        },
        async getList(pageNumber) {
            try {
                pageNumber && (this.param.pageNumber = pageNumber)
                this.loading = true
                const { records, total } = await this.$api.patch.softwarePatch(this.param)
                console.log(records, 'records')
                this.list = records
                this.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        },
        handleSizeChange(val) {
            this.param.pageSize = val;
            this.getList();
        },
    }
}
</script>
<style lang="scss" scoped>
.banl {
    overflow: hidden;
    height: 334px;
    margin: 0 -20px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    background: url(../../assets/imgs/authBgx.png) no-repeat;
    background-size: cover;

    .txt {
        position: absolute;
        top: 66px;
        left: 0;
        width: 100%;
        padding-left: 27px;

        .tit {
            font-size: 32px;
            color: #313133;
            line-height: 1.406em;
            overflow: hidden;
            margin-bottom: 25px;
            margin-top: 10px;
            font-weight: bold;
        }

        .desc {
            font-size: 14px;
            color: #636366;
            line-height: 1.428em;
            overflow: hidden;
        }
    }
}

.m-centerl {
    overflow: hidden;
    background-color: #fff;
    margin-top: -121px;
    padding: 40px 54px 30px 30px;
    z-index: 2;
    position: relative;
}

.ul-listl1 {
    overflow: hidden;
    margin-bottom: 30px;

    li {
        width: 50%;
        float: left;

        .con {
            display: block;
            overflow: hidden;
            height: 42px;
            line-height: 40px;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            text-align: center;
            transition: .5s;
            cursor: pointer;
        }

        .con:hover {
            background: #FF6A00;
            border-color: #FF6A00;
            color: #fff;
        }
    }

    li.act .con {
        background: #FF6A00;
        border-color: #FF6A00;
        color: #fff;
    }
}

.m-queryl {
    overflow: hidden;
}

.g-titl1 {
    font-size: 14px;
    color: #313133;
    line-height: 1.428em;
    overflow: hidden;
    padding-left: 12px;
    position: relative;

    i {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 14px;
        margin-top: -7px;
        background: #FF6A00;
    }
}

.jl-info {
    font-size: 12px;
    color: #636366;
    line-height: 18px;
    margin: 20px 0 50px;
}

.m-selectl {
    //padding-top: 30px;
    padding-bottom: 30px;

    .info {
        overflow: hidden;
        margin-bottom: 43px;

        .item {
            overflow: hidden;
            margin-right: 40px;
            margin-top: 30px;
            float: left;

            .ll {
                float: left;
                font-size: 12px;
                color: #303030;
                line-height: 32px;
                margin-right: 10px;
            }

            .rr {
                overflow: hidden;
            }
        }

        .c {
            clear: both;
            margin-bottom: 24px;
        }
    }

    .submit {
        overflow: hidden;
        width: 120px;

        .btn {
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #FF6A00;
            font-size: 14px;
            color: #fff;
            transition: .5s;
            cursor: pointer;
        }

        .btn:hover {
            background: #79bbff;
        }
    }
}

.jl-tab1 {
    text-align: center;
    margin-bottom: 22px;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 98px;
        height: 32px;
        background-color: #FF6A00;
        color: #fff;

        i {
            display: block;
            width: 8px;
            height: 8px;
            margin-left: 5px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    .txt {
        display: flex;
        align-items: center;
        line-height: 1;
        color: #FF6A00;
        cursor: pointer;

        i {
            display: block;
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin-left: 2px;
        }
    }
}

.jl-page {
    display: flex;
    justify-content: end;
}

</style>